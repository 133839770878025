<template>
	<div v-if="loaded" class="opacity-page">
		<div class="settings-content">
			<div class="list-crud row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="list">
						<div class="item" v-for="workSchedule in workSchedules" @click="viewItem(workSchedule)" v-bind:class="{active: workScheduleSelected.id == workSchedule.id}">
							<div class="name">{{ workSchedule.name }}</div>
							<div class="actions">
								<button @click.stop="editItem(workSchedule)"><icon-edit /></button>
								<button @click.stop="showModal('delete', {type: 'work_schedule', from: 'index_work_schedules', model: workSchedule})"><icon-trash /></button>
							</div>
						</div>
						<div class="item-empty" v-if="!workSchedules.length">
							{{ $t('work-schedule.no-result') }}
						</div>
					</div>
					<div class="crud" id="list-items">
						<div class="box" v-if="viewCrud == 'create'">
							<div class="header">
								<div class="icon-header"><icon-work-schedule /></div>
								<div class="name">{{ $t('work-schedule.create-template') }}</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
										<div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
										<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.name.$error}">
										<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('work-schedule.name-ph')" class="input-text no-icon" v-model="name">
									</div>
								</div>

								<div class="input-group">
									<div class="label-header">
										<label class="label">{{ $t('work-schedule.template') }}</label>
										<div v-if="errorsBe.schedules" class="error-msg">{{ errorsBe.schedules.join(" | ") }}</div>
									</div>

									<div class="template-days">
										<div class="day">
											<div class="label">{{ $t('general.monday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.monday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="monday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.monday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="monday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: monday.start || monday.end}" @click="monday.start || monday.end ?clearSchedule('monday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.tuesday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.tuesday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="tuesday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.tuesday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="tuesday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: tuesday.start || tuesday.end}" @click="tuesday.start || tuesday.end ?clearSchedule('tuesday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.wednesday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.wednesday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="wednesday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.wednesday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="wednesday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: wednesday.start || wednesday.end}" @click="wednesday.start || wednesday.end ?clearSchedule('wednesday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.thursday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.thursday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="thursday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.thursday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="thursday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: thursday.start || thursday.end}" @click="thursday.start || thursday.end ?clearSchedule('thursday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.friday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.friday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="friday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.friday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="friday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: friday.start || friday.end}" @click="friday.start || friday.end ?clearSchedule('friday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.saturday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.saturday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="saturday.start" :key="timepickerKey"></vue-timepicker>
												</div>
												<div class="input" v-bind:class="{has_error: $v.saturday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="saturday.end" :key="timepickerKey"></vue-timepicker>
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: saturday.start || saturday.end}" @click="saturday.start || saturday.end ?clearSchedule('saturday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.sunday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.sunday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="sunday.start" :key="timepickerKey"></vue-timepicker>
												</div>
												<div class="input" v-bind:class="{has_error: $v.sunday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="sunday.end" :key="timepickerKey"></vue-timepicker>
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: sunday.start || sunday.end}"  @click="sunday.start || sunday.end ? clearSchedule('sunday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
									</div>
								</div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message != ''">{{error_message}}</div>
									<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.add') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'edit'">
							<div class="header">
								<div class="icon-header"><icon-work-schedule /></div>
								<div class="name">{{ workScheduleSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="form">
								<div class="input-group mb-15">
									<div class="label-header">
										<label class="label">{{ $t('general.name') }}*</label>
									</div>
									<div class="input-box bg-white" v-bind:class="{has_error: $v.name.$error}">
										<div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
										<input type="text" :placeholder="$t('work-schedule.name-ph')" class="input-text no-icon" v-model="name">
									</div>
								</div>

								<div class="input-group">
									<div class="label-header">
										<label class="label">{{ $t('work-schedule.template') }}</label>
									</div>

									<div class="template-days">
										<div class="day">
											<div class="label">{{ $t('general.monday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.monday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="monday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.monday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="monday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: monday.start || monday.end}" @click="monday.start || monday.end ?clearSchedule('monday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.tuesday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.tuesday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="tuesday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.tuesday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="tuesday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: tuesday.start || tuesday.end}" @click="tuesday.start || tuesday.end ? clearSchedule('tuesday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.wednesday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.wednesday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="wednesday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.wednesday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="wednesday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: wednesday.start || wednesday.end}" @click="wednesday.start || wednesday.end ? clearSchedule('wednesday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.thursday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.thursday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="thursday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.thursday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="thursday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: thursday.start || thursday.end}" @click="thursday.start || thursday.end ? clearSchedule('thursday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.friday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.friday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="friday.start" :key="timepickerKey" />
												</div>
												<div class="input" v-bind:class="{has_error: $v.friday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="friday.end" :key="timepickerKey" />
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: friday.start || friday.end}" @click="friday.start || friday.end ? clearSchedule('friday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.saturday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.saturday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="saturday.start" :key="timepickerKey"></vue-timepicker>
												</div>
												<div class="input" v-bind:class="{has_error: $v.saturday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="saturday.end" :key="timepickerKey"></vue-timepicker>
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: saturday.start || saturday.end}" @click="saturday.start || saturday.end ? clearSchedule('saturday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
										<div class="day">
											<div class="label">{{ $t('general.sunday') }}</div>
											<div class="interval-inputs">
												<div class="input" v-bind:class="{has_error: $v.sunday.start.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.start')" format="HH:mm" :minute-interval="15" v-model="sunday.start" :key="timepickerKey"></vue-timepicker>
												</div>
												<div class="input" v-bind:class="{has_error: $v.sunday.end.$error}">
													<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours :placeholder="$t('general.end')" format="HH:mm" :minute-interval="15" v-model="sunday.end" :key="timepickerKey"></vue-timepicker>
												</div>
											</div>
											<div class="action">
												<button class="clear" v-bind:class="{active: sunday.start || sunday.end}" @click="sunday.start || sunday.end ? clearSchedule('sunday') : ''">
													<icon-close />
												</button>
											</div>
										</div>
									</div>
								</div>

								<div class="form-submit">
									<div class="error-msg-settings" v-show="error_message != ''">{{error_message}}</div>
									<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')">
										<div class="loader"></div>
										<span class="text">{{ $t('general.update') }}</span>
									</button>
								</div>
							</div>
						</div>
						<div class="box bg-white" v-else-if="viewCrud == 'view'">
							<div class="header">
								<div class="icon-header"><icon-work-schedule /></div>
								<div class="name">{{ workScheduleSelected.name }}</div>
								<div class="action-close">
									<button class="btn-tbf blue only-icon" @click="editItem(workScheduleSelected)">
										<div class="icon"><icon-edit /></div>
									</button>
									<button class="btn-tbf white only-icon" @click="resetToList('item')">
										<div class="icon"><icon-close /></div>
									</button>
								</div>
							</div>
							<div class="data-item">
								<div class="label">{{ $t('work-schedule.template') }}</div>
								<div class="template-days">
									<div class="day">
										<div class="label-day-name">{{ $t('general.monday') }}</div>

										<div class="value-day" v-if="workScheduleSelected.schedules.find(el => el.day == 'monday')">{{ workScheduleSelected.schedules.find(el => el.day == 'monday').start_time + ' - ' + workScheduleSelected.schedules.find(el => el.day == 'monday').end_time }}</div>
										<div class="value-day" v-else>{{ $t('general.free') }}</div>
									</div>
									<div class="day">
										<div class="label-day-name">{{ $t('general.tuesday') }}</div>
										<div class="value-day" v-if="workScheduleSelected.schedules.find(el => el.day == 'tuesday')">{{ workScheduleSelected.schedules.find(el => el.day == 'tuesday').start_time + ' - ' + workScheduleSelected.schedules.find(el => el.day == 'tuesday').end_time }}</div>
										<div class="value-day" v-else>{{ $t('general.free') }}</div>
									</div>
									<div class="day">
										<div class="label-day-name">{{ $t('general.wednesday') }}</div>
										<div class="value-day" v-if="workScheduleSelected.schedules.find(el => el.day == 'wednesday')">{{ workScheduleSelected.schedules.find(el => el.day == 'wednesday').start_time + ' - ' + workScheduleSelected.schedules.find(el => el.day == 'wednesday').end_time }}</div>
										<div class="value-day" v-else>{{ $t('general.free') }}</div>
									</div>
									<div class="day">
										<div class="label-day-name">{{ $t('general.thursday') }}</div>
										<div class="value-day" v-if="workScheduleSelected.schedules.find(el => el.day == 'thursday')">{{ workScheduleSelected.schedules.find(el => el.day == 'thursday').start_time + ' - ' + workScheduleSelected.schedules.find(el => el.day == 'thursday').end_time }}</div>
										<div class="value-day" v-else>{{ $t('general.free') }}</div>
									</div>
									<div class="day">
										<div class="label-day-name">{{ $t('general.friday') }}</div>
										<div class="value-day" v-if="workScheduleSelected.schedules.find(el => el.day == 'friday')">{{ workScheduleSelected.schedules.find(el => el.day == 'friday').start_time + ' - ' + workScheduleSelected.schedules.find(el => el.day == 'friday').end_time }}</div>
										<div class="value-day" v-else>{{ $t('general.free') }}</div>
									</div>
									<div class="day">
										<div class="label-day-name">{{ $t('general.saturday') }}</div>
										<div class="value-day" v-if="workScheduleSelected.schedules.find(el => el.day == 'saturday')">{{ workScheduleSelected.schedules.find(el => el.day == 'saturday').start_time + ' - ' + workScheduleSelected.schedules.find(el => el.day == 'saturday').end_time }}</div>
										<div class="value-day" v-else>{{ $t('general.free') }}</div>
									</div>
									<div class="day">
										<div class="label-day-name">{{ $t('general.sunday') }}</div>
										<div class="value-day" v-if="workScheduleSelected.schedules.find(el => el.day == 'sunday')">{{ workScheduleSelected.schedules.find(el => el.day == 'sunday').start_time + ' - ' + workScheduleSelected.schedules.find(el => el.day == 'sunday').end_time }}</div>
										<div class="value-day" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
	</div>
	<loader-settings-page v-else/>
</template>

<script>
	import IconClose from '../../Icons/Close'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconWorkSchedule from '../../Icons/WorkSchedule'
	import IconRole from '../../Icons/Role'
	import LoaderSettingsPage from '../../PagesLoaders/SettingsPage'
	import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconEdit,
			IconTrash,
			IconWorkSchedule,
			IconRole,
			LoaderSettingsPage
		},
		data() {
			return {
				loaded: false,
				viewCrud: 'create',
				workSchedules: [],
				workScheduleSelected: '',
				name: '',
				monday: {start: '', end: ''},
				tuesday: {start: '', end: ''},
				wednesday: {start: '', end: ''},
				thursday: {start: '', end: ''},
				friday: {start: '', end: ''},
				saturday: {start: '', end: ''},
				sunday: {start: '', end: ''},
				timepickerKey: 1,
				error_message: '',
				errorsBe: {}
			}
		},
		async mounted(){
			await this.getWorkSchedules()

			setTimeout(() => {
				var title = this.$t('settings-navbar.work-schedules');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshWrokSchedulesIndex', () => {
				this.viewCrud = 'create';
				this.getWorkSchedules()
			})

			if(!this.$auth.user().rights.admins_instance_crud){
				this.$router.push({name: 'forbbiden'})
			}
		},
		validations: {
			name: {required},
			monday: {
				start: { required: requiredIf(function (model) {
        			return this.monday.end
        		})},
        		end: { required: requiredIf(function (model) {
        			return this.monday.start
        		})}
			},
			tuesday: {
				start: { required: requiredIf(function (model) {
        			return this.tuesday.end
        		})},
        		end: { required: requiredIf(function (model) {
        			return this.tuesday.start
        		})}
			},
			wednesday: {
				start: { required: requiredIf(function (model) {
        			return this.wednesday.end
        		})},
        		end: { required: requiredIf(function (model) {
        			return this.wednesday.start
        		})}
			},
			thursday: {
				start: { required: requiredIf(function (model) {
        			return this.thursday.end
        		})},
        		end: { required: requiredIf(function (model) {
        			return this.thursday.start
        		})}
			},
			friday: {
				start: { required: requiredIf(function (model) {
        			return this.friday.end
        		})},
        		end: { required: requiredIf(function (model) {
        			return this.friday.start
        		})}
			},
			saturday: {
				start: { required: requiredIf(function (model) {
        			return this.saturday.end
        		})},
        		end: { required: requiredIf(function (model) {
        			return this.saturday.start
        		})}
			},
			sunday: {
				start: { required: requiredIf(function (model) {
        			return this.sunday.end
        		})},
        		end: { required: requiredIf(function (model) {
        			return this.sunday.start
        		})}
			}
		},
		methods: {
			async getWorkSchedules(){
				await axios.get(this.$auth.user().instance.id + '/work-schedules')
				.then(({data}) => {
					this.workSchedules = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var schedules = []

					if(this.monday.start && this.monday.end){
						var mondayObj = {day: 'monday', start_time: this.monday.start, end_time: this.monday.end}
						if(this.monday.id){
							mondayObj.id = this.monday.id
						}
						schedules.push(mondayObj)
					}
					if(this.tuesday.start && this.tuesday.end){
						var tuesdayObj = {day: 'tuesday', start_time: this.tuesday.start, end_time: this.tuesday.end}
						if(this.tuesday.id){
							tuesdayObj.id = this.tuesday.id
						}
						schedules.push(tuesdayObj)
					}
					if(this.wednesday.start && this.wednesday.end){
						var wednesdayObj = {day: 'wednesday', start_time: this.wednesday.start, end_time: this.wednesday.end}
						if(this.wednesday.id){
							wednesdayObj.id = this.wednesday.id
						}
						schedules.push(wednesdayObj)
					}
					if(this.thursday.start && this.thursday.end){
						var thursdayObj = {day: 'thursday', start_time: this.thursday.start, end_time: this.thursday.end}
						if(this.thursday.id){
							thursdayObj.id = this.thursday.id
						}
						schedules.push(thursdayObj)
					}
					if(this.friday.start && this.friday.end){
						var fridayObj = {day: 'friday', start_time: this.friday.start, end_time: this.friday.end}
						if(this.friday.id){
							fridayObj.id = this.friday.id
						}
						schedules.push(fridayObj)
					}
					if(this.saturday.start && this.saturday.end){
						var saturdayObj = {day: 'saturday', start_time: this.saturday.start, end_time: this.saturday.end}
						if(this.saturday.id){
							saturdayObj.id = this.saturday.id
						}
						schedules.push(saturdayObj)
					}
					if(this.sunday.start && this.sunday.end){
						var sundayObj = {day: 'sunday', start_time: this.sunday.start, end_time: this.sunday.end}
						if(this.sunday.id){
							sundayObj.id = this.sunday.id
						}
						schedules.push(sundayObj)
					}

					var objData = {
						name: this.name.charAt(0).toUpperCase() + this.name.slice(1),
						schedules: schedules
					}

					if(type == 'create'){
						this.createWorkSchedule(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateWorkSchedule(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createWorkSchedule(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/work-schedules/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							this.getWorkSchedules()
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.workScheduleSelected = data.data;
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			updateWorkSchedule(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/work-schedules/${this.workScheduleSelected.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							this.getWorkSchedules()
							this.resetToList()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
							this.workScheduleSelected = this.workSchedules.find(el => el.id == this.workScheduleSelected.id)
							this.viewCrud = 'view'
						}, 1000)
					}, 300)
				})
				.catch(error => {
					this.errorsBe = error.response.data.errors
					btnSubmitText.innerHTML = this.$t('btn-submit.error')
					btnSubmit.classList.add('error')
					btnSubmitLoader.classList.remove('onProgress', 'finish')
					btnSubmit.classList.remove('loading')
					setTimeout(()=>{
						btnSubmit.classList.remove('error')
						btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
						btnSubmit.disabled = false
						if(error.response.status == 500){
							this.error_message = this.$t('error.500')
						}
					}, 1000)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			editItem(workSchedule){
				this.scrollTop()
				this.viewCrud = 'edit'
				
				this.workScheduleSelected = workSchedule
				this.name = workSchedule.name

				var monday = this.workScheduleSelected.schedules.find(el => el.day == 'monday')
				var tuesday = this.workScheduleSelected.schedules.find(el => el.day == 'tuesday')
				var wednesday = this.workScheduleSelected.schedules.find(el => el.day == 'wednesday')
				var thursday = this.workScheduleSelected.schedules.find(el => el.day == 'thursday')
				var friday = this.workScheduleSelected.schedules.find(el => el.day == 'friday')
				var saturday = this.workScheduleSelected.schedules.find(el => el.day == 'saturday')
				var sunday = this.workScheduleSelected.schedules.find(el => el.day == 'sunday')
				
				if(monday){
					this.monday = { id: monday.id, start: monday.start_time, end: monday.end_time }
				}else{
					this.monday = {start: '', end: ''}
				}

				if(tuesday){
					this.tuesday = { id: tuesday.id, start: tuesday.start_time, end: tuesday.end_time }
				}else{
					this.tuesday = {start: '', end: ''}
				}

				if(wednesday){ 
					this.wednesday = { id: wednesday.id, start: wednesday.start_time, end: wednesday.end_time } 
				}else{
					this.wednesday = {start: '', end: ''}
				}

				if(thursday){ 
					this.thursday = { id: thursday.id, start: thursday.start_time, end: thursday.end_time } 
				}else{
					this.thursday = {start: '', end: ''}
				}

				if(friday){
					this.friday = { id: friday.id, start: friday.start_time, end: friday.end_time }
				}else{
					this.friday = {start: '', end: ''}
				}

				if(saturday){ 
					this.saturday = { id: saturday.id, start: saturday.start_time, end: saturday.end_time }
				}else{
					this.saturday = {start: '', end: ''}
				}

				if(sunday){
					this.sunday = { id: sunday.id, start: sunday.start_time, end: sunday.end_time }
				}else{
					this.sunday = {start: '', end: ''}
				}

				this.timepickerKey += 1
			},
			resetToList(from){
				this.viewCrud = 'create'
				if(from == 'item') {
					this.workScheduleSelected = ''
				}
				this.name = ''
				this.description = ''
				this.monday = {start: '', end: ''}
				this.tuesday = {start: '', end: ''}
				this.wednesday = {start: '', end: ''}
				this.thursday = {start: '', end: ''}
				this.friday = {start: '', end: ''}
				this.saturday = {start: '', end: ''}
				this.sunday = {start: '', end: ''}
				this.timepickerKey += 1
			},
			viewItem(item){
				this.scrollTop()
				this.viewCrud = 'view'
				this.workScheduleSelected = item
			},
			clearSchedule(day){
				if(day == 'monday'){ this.monday = {start: '', end: ''} }
				if(day == 'tuesday'){ this.tuesday = {start: '', end: ''} }
				if(day == 'wednesday'){ this.wednesday = {start: '', end: ''} }
				if(day == 'thursday'){ this.thursday = {start: '', end: ''} }
				if(day == 'friday'){ this.friday = {start: '', end: ''} }
				if(day == 'saturday'){ this.saturday = {start: '', end: ''} }
				if(day == 'sunday'){ this.sunday = {start: '', end: ''} }

				this.timepickerKey += 1
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			scrollTop() {
				var top_value = {scrollTop:$('#list-items').position().top}
				if(top_value.scrollTop !== 135){
					$('div').animate({scrollTop:0}, 'slow');
				}
			}
		}
	};
</script>

<style lang="scss">
	.template-days{
		.day{
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			&:last-child{
				margin-bottom: 0;
			}
			.label, .label-day-name{
				min-width: 100px;
				font-size: 14px;
				flex: 1;
			}
			.label-day-name{
				color: $grey;
			}
			.value-day{
				font-size: 14px;
			}
			.interval-inputs{
				display: flex;
				.input{
					flex: 1;
					&:first-child{
						padding-right: 5px;
					}
					&:last-child{
						padding-left: 5px;
					}
					&.has_error{
						.vue__time-picker{
							input.display-time{
								border-color: $red;
								box-shadow: 0 1px 3px 0 rgba(235, 27, 104, 0.2);
							}
						}
					}
				}
				.vue__time-picker{
					width: 100%;
					input.display-time{
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						background: #FFFFFF;
						border-radius: 6px;
						border: 1px solid #dcdee1;
						width: 100%;
						height: 30px;
						font-size: 12px;
						cursor: pointer;
						&:focus{
							outline: none;
						}
					}
					.controls{
						display: none;
					}
					.dropdown{
						width: 120px;
						height: 120px;
						top: 32px;
						border-radius: 6px;
						border: 1px solid $borderColor;
						box-shadow: 0px 9px 16px rgb(0 0 0 / 3%);
						.select-list{
							width: 100%;
							height: 100%;
							.hours, .minutes{
								li{
									font-size: 14px;
									padding: 3px 0;
									&.hint{
										font-size: 12px;
									}
									&.active{
										background: $blue;
									}
									&:focus{
										outline: none;
									}
								}
							}
						}
					}
				}
			}
			.action{
				width: 30px;
				min-width: 30px;
				button{
					background: #FFFFFF;
					border-radius: 6px;
					border: 1px solid #dcdee1;
					margin-left: 5px;
					padding: 0;
				    width: 30px;
				    height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0.4;
					cursor: not-allowed;
					&.active{
						opacity: 1;
				    	cursor: pointer;
				    	&:hover{
				    		.stroke-color{
				    			stroke: $primary;
				    		}
				    	}
					}
					
					&:focus{
						outline: none;
					}
					.stroke-color{
						stroke: $placeholderColor;
					}
				}
			}
		}
	}
</style>